import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { domainOriginSource, kindOfContracts, splitColumn, splitRows, parseDate, getDaysArray, typeOfProjects, statusOfProjects, sumOfColumn  } from '../helpers';
import swal from 'sweetalert';
// import Projectview from './Projectview'

// import { ProductService } from './service/ProductService';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
// import { ProductService } from './service/ProductService';
let domainOrigin = domainOriginSource();


let porjectTypes = typeOfProjects();
let projectStatus = statusOfProjects();

export default function Projectlist(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [countByDate, setCountByDate] = useState("");
    const [rowClick, setRowClick] = useState(true);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    
    const onRowExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };

    const dataByDate = [];
    // console.log({TableName, productHandle});   
    let [listCount, setListCount] = useState(1);
    const [ignored, forceUpdate] = useState(listCount);

    const [date, setDate] = useState(null);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };
    

    const cols = [
        { field: 'phone', header: 'Phone' },
        { field: 'email', header: 'Email' },
        { field: 'origin', header: 'Origin' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // const dt = useRef(null);
    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, items);
                doc.save("daily-records"+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "projects");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const [selectedRows, setSelectedRows] = useState([]);
    // console.log(selectedRows);
    function deleteSelectedRows(choice) {
        let selectedRowsData = splitColumn(selectedRows, "sr");
        let text = "Do you wanna remove "+ selectedRowsData.length + " project list?";
        swal({
            title: "Are you sure?",
            text: text,
            icon: "warning",
            dangerMode: true,
          })
          .then(willDelete => {
            if (willDelete) {
                axios({
                    method: 'delete',
                    url: domainOrigin+'/project/remove',
                    data: {selectedRowsData}
                })
                .then((response) => {
                    // console.log(response);
                    if(response.status == 200){
                        // alert(response.data);
                        swal("Deleted!", "Your seleted records has deleted!", "success");
                        setListCount(listCount + 1);
                        setSelectedRows([]);
                    }
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // alert(error.message);
                        swal({
                            title: "Errors Found",
                            text: error.message,
                            icon: "warning",
                            dangerMode: true,
                        })
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the 
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        // console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.log('Error', error.message);
                        swal({
                            title: "Errors Found",
                            text: error.message,
                            icon: "warning",
                            dangerMode: true,
                        })
                    }
                    // console.log(error.config);
                });
            }
        });
    }

    // Filter records by user name
    let userDetails = props.userDetails;
    let allItems = items;
    let currentUserName = userDetails.userName;
    let currentUserFName = currentUserName.split(" ").first;
    let projects = items;

    const [dateRange, setdateRange] = useState(null);
    let fromDate = '';
    let endDate = '';
    if(dateRange){
        let unFilteredRecords = projects;
        if(dateRange[0]){
            fromDate = parseDate(dateRange[0]);
        }
        if(dateRange[1]){
            endDate = parseDate(dateRange[1]);
        }

        var selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
        selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");

        let filteredRecords = []
        selectedDays.forEach(function(selectedDay){
            let recordDay = parseDate(selectedDay);
            filteredRecords.push(splitRows(unFilteredRecords, 'date', recordDay));
        })

        if(filteredRecords.length > 0){
            projects = filteredRecords.flat();
        }
    }
    console.log({dateRange});

    const renderHeader = () => {
        let itemsCount = projects.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            // console.log(".. "+ listCount);
        }
        
        return (
            <div className="d-flex flex-wrap justify-content-between">
                <div className="heading full-width">
                    <small onClick={changeState}>Showing Projects (Count: {itemsCount})</small>
                </div>
                <div className='d-flex flex-wrap justify-content-between full-width'>
                    <div className="py-1 d-flex align-items-center justify-content-end gap-2"> 
                        <span className="p-input-icon-left">
                            <i className="pi pi-search small" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                        </span>
                        <Calendar value={dateRange} onChange={(e) => setdateRange(e.value)} selectionMode="range" readOnlyInput showIcon />
                    </div>  
                    <div className="py-1 d-flex align-items-center justify-content-end gap-2">
                        {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                        <Button type="button" selected-row={selectedRows.length} className="danger" label="Delete" severity="danger" onClick={deleteSelectedRows}/>
                        <Button type="button" label="Refresh" onClick={changeState} />
                        <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                        {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    </div>
                    {/* <Button icon="pi pi-download" /> */}
                </div>
            </div>
        );
    };
    const header = renderHeader();
   
    const footer = `In total there are ${projects ? projects.length : 0} projects.`;
    
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/projects";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }
        getCount()
        // forceUpdate();
    }, [listCount])

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        let editedRow = _items[index];
        // setProducts(_rowData);
        console.log({editedRow})

        let date = editedRow.date;
        let parsedDate = parseDate(date);
        const editedRowData = {... editedRow, id : editedRow.id, ["date"]: parsedDate};
        
        axios({
            method: 'post',
            url: domainOrigin+'/project/update',
            data: {editedRowData}
        })
        .then((response) => {
            if(response.status == 200){
                // alert("Row Updated");
                listCount = listCount + 1;
                setListCount(listCount);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // alert(error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: true,
                })
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: true,
                })
            }
            console.log(error.config);
        });

    };

    const contractEditor = (options) => {
        const typeOfContracts = kindOfContracts();
        return <Dropdown value={options.value} onChange={(e) => options.editorCallback(e.target.value.name)} options={porjectTypes} optionLabel="name"  placeholder={(options.value)? options.value : "Billable"} className="w-full md:w-14rem" />;
    };

    const statusEditor = (options) => {
        const typeOfContracts = kindOfContracts();
        return <Dropdown value={options.value} onChange={(e) => options.editorCallback(e.target.value.name)} options={projectStatus} optionLabel="name"  placeholder={(options.value)? options.value : "Billable"} className="w-full md:w-14rem" />;
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const textAreaEditor = (options) => {
        return <InputTextarea className="p-inputtext-sm" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const dateEditor = (options) => {
        return <Calendar className="" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} name='dateRange' selectionMode="single" dateFormat="d MM yy" placeholder={options.value} showButtonBar />;
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const workedHours = (rowData) => {
        let project_id = rowData.project_id;
        let projectEnteries = splitRows(props.dailyReportings, "project_id", project_id);
        let timeSpents = splitColumn(projectEnteries, "worked_hours");
        let totalTimeSpents = 0;
        if(timeSpents.length > 0){
            let timeSpentRaw = [];
            timeSpents.forEach(function(timeSpent){
                let TotalMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                let workedHours = Number(TotalMinute / 60);
                timeSpentRaw.push(Number(totalTimeSpents + workedHours).toFixed(2));
            })
            totalTimeSpents = sumOfColumn(timeSpentRaw).toFixed(2);
        }else {
            totalTimeSpents = 0;
        }
        return totalTimeSpents; 
    }

    const rowExpansionTemplate = (data) => {
        console.log(data.project_id)
        let projectEntries = splitRows(props.dailyReportings, "project_id", data["project_id"]);
        console.log(projectEntries)
        const labelBilling = (rowData) => {
            let typeofBilling = rowData.type_of_contract;
            console.log({typeofBilling})
            return (
                <span className={`pill-label ${typeofBilling}`}>{typeofBilling.replace("-", " ")}</span>
            );
        };

        return (
            <DataTable value={projectEntries}>
                <Column className="date" editor={(options) => dateEditor(options)} field="date" header="Date"></Column>
                <Column field="project_id" header="Project ID"></Column>
                <Column field="freelancer_name" header="Freelancer Name"></Column>
                <Column field="client_name" header="Client Name"></Column>
                <Column field="type_of_contract" editor={(options) => contractEditor(options)} header="Type of Contract" body={labelBilling}></Column>
                <Column field="worked_hours" editor={(options) => textEditor(options)} header="Worked Hours"></Column>
                <Column field="task_memo" editor={(options) => textAreaEditor(options)} header="Task Memo"></Column>
            </DataTable>
        );
    };

    const allowExpansion = (rowData) => {
        return true;
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading Projects...</div>;
    } else {
        const projectStatus = (rowData) => {
            let projectStatus = rowData.project_status;
            return (
                <span className={`pill-label ${projectStatus.replace(" ", "-").toLowerCase()}`}>{projectStatus}</span>
            );
        };

        return (
        <div className="full-width">
            <div className="data-table-preview full-width">
                <Toast ref={toast} />
                <DataTable className="lead-table" value={projects} selectionMode={rowClick ? null : 'checkbox'} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} onRowEditComplete={onRowEditComplete} paginator scrollable filters={filters} size="small" header={header} 
                loading={loading} 
                scrollHeight="70vh" 
                rows={30} 
                editMode="row" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate} 
                rowsPerPageOptions={[30, 100, 250]} 
                tableStyle={{ minWidth: '100%' }} 
                globalFilterFields={['date', 'freelancer_name', 'client_name', 'type_of_contract']}>
                    <Column selectionMode="multiple"></Column>
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column className="date" editor={(options) => dateEditor(options)} field="date" header="Date"></Column>
                    <Column field="project_id" header="Project ID"></Column>
                    <Column field="project_name" editor={(options) => textEditor(options)} header="Project Name"></Column>
                    <Column field="client_name" editor={(options) => textEditor(options)} header="Client Name"></Column>
                    <Column field="project_status" editor={(options) => statusEditor(options)} header="Project Status" body={projectStatus}></Column>
                    <Column field="project_type" editor={(options) => contractEditor(options)} header="Project Type"></Column>
                    <Column field="budget_hours" editor={(options) => textEditor(options)} header="Budget Hours"></Column>
                    <Column header="Worked Hours" body={workedHours}></Column>
                    <Column className="project_deadline" editor={(options) => dateEditor(options)} field="project_deadline" header="Project Deadline"></Column>
                    {/* <Column field="project_url" editor={(options) => textEditor(options)} header="Project Url"></Column> */}
                    {/* <Column field="project_description" editor={(options) => textAreaEditor(options)} header="Project Description"></Column> */}
                    <Column rowEditor header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        </div>
        );
    }
}