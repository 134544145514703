import React, { useState } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { Dropdown } from 'primereact/dropdown';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
const Myaccount = () => {
    // const [userData, setUserData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const [Data, setData] = useState({
        employee_name: "",
        employee_phone: "",
        employee_id: "",
        department: "",
    });

    const [SignUpData, setSignUpData] = useState({
        employee_name: "",
        employee_phone: "",
        employee_id: "",
        department: "",
    });

    const [loginData, setLoginData] = useState({
        employee_email: "",
        password: ""
    })

    const [errors, setErrors] = useState({
        employee_name: "",
        employee_phone: "",
        employee_id: "",
        department: "",
        employee_email: "",
        password: ""
    });

    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        setErrors({
            employee_name: "",
            employee_phone: "",
            employee_id: "",
            department: "",
            employee_email: "",
            password: ""
        });
    }

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        let department = SignUpData.department.name;

        if (SignUpData.employee_name.trim() === "") {
            newErrors.employee_name = "Employee Name is required";
            isValid = false;
        }

        if (SignUpData.employee_phone.trim() === "") {
            newErrors.employee_phone = "Employee Phone is required";
            isValid = false;
        } else if (SignUpData.employee_phone.length !== 10) {
            newErrors.employee_phone = "Enter a valid 10-digit phone number.";
            isValid = false;
        }

        if (SignUpData.employee_id.trim() === "") {
            newErrors.employee_id = "Employee ID is required";
            isValid = false;
        }

        if (department.trim() === "") {
            newErrors.department = "Department is required";
            isValid = false;
        }

        if (SignUpData.employee_email.trim() === "") {
            newErrors.employee_email = "Employee Email is required";
            isValid = false;
        } else if (!isValidEmail(SignUpData.employee_email)) {
            newErrors.employee_email = "Invalid email format";
            isValid = false;
        }

        if (SignUpData.password.trim() === "") {
            newErrors.password = "Password is required";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const HandleLoginFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLoginData({ ...loginData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    }
    const doLogin = (e) => {
        e.preventDefault();
        let submitBtn = e.target.querySelector("button[type=submit] .p-button-label");
        submitBtn.innerText = "Logging In...";
        submitBtn.setAttribute("disabled", "");
        const authenticateData = {...loginData};
        // Replace this with your authentication logic
        // You should make an API call to check user credentials
        // If authentication is successful, navigate to the dashboard
        console.log({authenticateData});
        axios({
            method: 'post',
            url: domainOrigin+'/users-list/authenticate',
            data: {authenticateData}
        })
        .then((response) => {
            if (response.status === 200) {
                submitBtn.innerText = "Logged In";
                
                let matchQueryRequestResponse = response.data.matchQueryRequestResponse
                console.log(response.data.matchQueryRequestResponse);
                var employee_name = matchQueryRequestResponse.employee_name;
                var employee_email = matchQueryRequestResponse.employee_email;
                var employee_id = matchQueryRequestResponse.employee_id;
                var department = matchQueryRequestResponse.department;
                var employee_phone = matchQueryRequestResponse.employee_phone;
                var access_allowed = matchQueryRequestResponse.access_allowed;
                var role = matchQueryRequestResponse.role;

                // userProfile.setUserName = employee_name;
                // userProfile.setUserEmail = employee_email;

                Cookies.set('loggedIn', true);
                Cookies.set('userName',employee_name);
                Cookies.set('userEmail',employee_email);
                Cookies.set('userEmployeeID',employee_id);
                Cookies.set('userDepartment', department);
                Cookies.set('access_allowed', access_allowed);
                Cookies.set('employee_phone', employee_phone);
                Cookies.set('user_role', role);

                navigate("/dashboard");
                window.location.reload();
            }
        }) 
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            submitBtn.innerText = error.message+" Try Again";

            setTimeout(function(){
                submitBtn.innerText = "Log In";
                submitBtn.removeAttribute("disabled");
            }, 2000)
            console.log(error.config);
        });

        
    }

    const HandleSignupFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setSignUpData({ ...SignUpData, [name]: value });
        setErrors({ ...errors, [name]: "" });
        console.log(value);
    }
    const doSignup = (e) => {
        e.preventDefault();
        let submitBtn = e.target.querySelector("button[type=submit] .p-button-label");
        submitBtn.innerText = "Creating Your Account...";
        submitBtn.setAttribute("disabled", "");

        // Validate the form
        const isValid = validateForm();

        if (isValid) {
            // Proceed with form submission
            let department = SignUpData.department.name;
            const newdata = { ...SignUpData, id: new Date().getTime().toString(), ["department"]: department };
            console.log(newdata);
            // Instead of pushing data into 'users' array, you should make an API call to register the user
            axios.post(`${domainOrigin}/users-list/add`, newdata)
                .then((response) => {
                    if (response.status === 200) {
                        submitBtn.innerText = "Account Created Succesfully";
                        // alert("Your Account Created Succesfully");
                    }
                })
                .catch((error) => {
                    console.log(error)
                    console.error('Error:', error.message);
                    submitBtn.innerText = error.message+" Try Again";
                    setTimeout(function(){
                        submitBtn.removeAttribute("disabled");
                        submitBtn.innerText = "Create Account";
                    }, 2000)

                    if (error.response) {
                        console.error('Response Data:', error.response.data);
                        console.error('Response Status:', error.response.status);
                    } else if (error.request) {
                        console.error('Request:', error.request);
                    } else {
                        console.error('Error Config:', error.config);
                    }
                });
        }
    }

    const departmentList = [
        { name: "Select A Department", code: ""},
        { name: 'Developer', code: 'developer' },
        { name: 'Digital Marketing', code: 'digital-marketing' },
        { name: 'Data Entry Support', code: 'data-entry' },
        { name: 'Designer', code: 'designer' }
    ];

    return (
        <div className='my-account full-width'>
            <div className='full-width'>
                <div className='row'>
                    <div className='col-10 col-sm-6 col-md-5 col-lg-3 float-none my-5 mx-auto'>
                        <div className='logo-bar w-100 text-center mb-3'>
                            <img width="100" height="auto" src="/milgenx-full.png" alt="platform-logo" className="platform-logo" />
                        </div>
                        <div className="card flex flex-column align-items-center text-left p-0">
                            <TabView activeIndex={activeIndex} onTabChange={handleTabChange} className="full-width">
                                <TabPanel header="Login" className="">
                                    <form className="form-theme full-width p-float-label p-3 pb-2" onSubmit={doLogin}>
                                        <div className="col-12 field-row">
                                            <InputText className="p-inputtext-sm" type='text' placeholder="Email" autoComplete="off" name="employee_email" value={loginData.employee_email} onChange={HandleLoginFieldChange} required/>
                                            {errors.employee_email && <div className="error">{errors.employee_email}</div>}
                                        </div>
                                        <div className="col-12 field-row">
                                            <InputText className="p-inputtext-sm" type='password' placeholder="Password" autoComplete="off" name="password" value={loginData.password} onChange={HandleLoginFieldChange} required/>
                                        </div>
                                        <Button type='submit' label="Log In" style={{ height: 28, width: '100%', marginTop: '5px' }} />
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} className='bottom'>
                                            <div className='w-100 text-center mt-2 text-small'>
                                                Not a Member? <span onClick={() => setActiveIndex(1)} className="p-link-text">Create Account</span>
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel id="codeSection" header="Signup" className=''>
                                    <form className="form-theme full-width p-float-label p-3 pb-2" onSubmit={doSignup}>
                                        <div className="col-12 field-row">
                                            <small className='form-announcement text-small'>All fields with asterisk (*) are mandatory</small>
                                        </div>
                                        <div className="col-12 field-row">
                                            <InputText className="p-inputtext-sm" type='text' placeholder="Employee Name*" autoComplete="off" name="employee_name" value={SignUpData.employee_name} onChange={HandleSignupFieldChange} required/>
                                            {errors.employee_name && <div className="error">{errors.employee_name}</div>}
                                        </div>
                                        <div className="col-12 field-row">
                                            <InputText className="p-inputtext-sm" type='text' placeholder="Employee Phone*" autoComplete="off" name="employee_phone" value={SignUpData.employee_phone} onChange={HandleSignupFieldChange} required/>
                                            {errors.employee_phone && <div className="error">{errors.employee_phone}</div>}
                                        </div>
                                        <div className="col-12 field-row">
                                            <InputText className="p-inputtext-sm" type='text' placeholder="Employee ID*" autoComplete="off" name="employee_id" value={SignUpData.employee_id} onChange={HandleSignupFieldChange} required/>
                                            {errors.employee_id && <div className="error">{errors.employee_id}</div>}
                                        </div>
                                        <div className="col-12 field-row">
                                            <Dropdown value={SignUpData.department} onChange={HandleSignupFieldChange} options={departmentList} name="department" optionLabel="name"  placeholder={(SignUpData.department)? SignUpData.department : "Select A Department"} required/>
                                            {/* <InputText className="p-inputtext-sm" type='text' placeholder="Department*" autoComplete="off" name="department" value={SignUpData.department} onChange={HandleSignupFieldChange} /> */}
                                            {errors.department && <div className="error">{errors.department}</div>}
                                        </div>
                                        <div className="col-12 field-row">
                                            <InputText className="p-inputtext-sm" type='text' placeholder="Employee Email*" autoComplete="off" name="employee_email" value={SignUpData.employee_email} onChange={HandleSignupFieldChange} required/>
                                            {errors.employee_email && <div className="error">{errors.employee_email}</div>}
                                        </div>
                                        <div className="col-12 field-row d-flex justify-content-between">
                                            <InputText className="p-inputtext-sm" type='password' placeholder="Password*" autoComplete="off" name="password" value={SignUpData.password} onChange={HandleSignupFieldChange} required/>
                                            {errors.password && <div className="error">{errors.password}</div>}
                                        </div>
                                        <Button type='submit' label="Create Account" style={{ height: 28, width: '100%', marginTop: '5px' }} />
                                        <div className='w-100 text-center mt-2 text-small'>
                                            Already a Member? <span onClick={() => setActiveIndex(0)} className="p-link-text">Log In</span>
                                        </div>
                                    </form>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Myaccount;
