import React, { useState, useRef }  from 'react';
import { InputText } from "primereact/inputtext";
// import { InputNumber } from "primereact/inputnumber";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from "primereact/inputtextarea";
import axios, {isCancel, AxiosError} from 'axios';
import { Calendar } from 'primereact/calendar';
import Cookies from 'js-cookie';
import { kindOfContracts, isLoggedIn, splitColumn, removeDuplicates, splitRows } from '../helpers';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

// import { classNames } from 'primereact/utils';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Addrecord = (props) => {
    const [userData, setUserData] = useState(isLoggedIn(Cookies));
    const [projectList, setProjectList] = useState([]);

    // const [resData, setResData ] = useState([]); 
    let [listCount, setListCount] = useState(1);
    const [newRecordData, setnewRecordData] = useState({
        date : new Date(),
        freelancer_name : userData.userName,
        task_memo : "",
        client_name : "",
        type_of_contract : "",
        worked_hours : "",
    });

    let typeOfContracts = kindOfContracts();
    
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        // console.log(name, value)
        let clientName = '';
        if([name] == "client_name"){
            let clientProjects = [];
            if(typeof value  !== 'object'){
                clientName = value;
                clientProjects = splitRows(splitRows(props.projects, "project_status", "In Progress"), "client_name", clientName);
            }else if(typeof value  === 'object'){
                clientName = value.clientName;
                clientProjects = splitRows(splitRows(props.projects, "project_status", "In Progress"), "client_name", clientName);
            }
            console.log({clientProjects, clientName})

            if(clientProjects.length > 0){
                projectList.splice(0, projectList.length);
                clientProjects.forEach(function(clientProject){
                    projectList.push({name: clientProject.project_name, code: clientProject.project_id})
                })
                setProjectList(projectList);
            }else{
                projectList.splice(0, projectList.length);
                setProjectList(projectList);
            }
            console.log({clientName, projectList})
        }
        setnewRecordData({...newRecordData, [name] : value });
    }

    const navigate = useNavigate();
    const refreshPage = () => {
        navigate(0); // Navigates to the current route, effectively refreshing the page
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        let date = newRecordData.date;
        let folteretedDate = date.toLocaleString('en-IN', {timeZone: 'IST'}).split(',')[0].split("/");
        let parsedDate = folteretedDate[1]+"/"+folteretedDate[0]+"/"+folteretedDate[2];

        let formWraper = e.target;
        let submitBtn = formWraper.querySelector("button[type=submit]");
        submitBtn.querySelector("span.p-button-label").innerText = submitBtn.getAttribute("in-progress");

        let typeOfContract = newRecordData.type_of_contract.code;
        let project_id = (newRecordData.project_id != undefined ) ? newRecordData.project_id.code : "";

        let clientName = '';
        if(typeof newRecordData.client_name  !== 'object'){
            clientName = newRecordData.client_name;
        }else if(typeof newRecordData.client_name  === 'object'){
            clientName = newRecordData.client_name.clientName;
            console.log({clientName})
        }

        const newRecord = {...newRecordData, id : new Date().getTime().toString(), ["date"]: parsedDate, ["type_of_contract"] : typeOfContract, ["client_name"] : clientName, ["project_id"] : project_id };
        axios({
            method: 'post',
            url: domainOrigin+'/daily-reporting/submit',
            data: {newRecord}
        })
        .then((response) => {
            if(response.status === 200){
                // alert("Record Added Successfully");
                submitBtn.querySelector("span.p-button-label").innerText = submitBtn.getAttribute("aria-label");
                swal({
                    title: "Added Successfully",
                    icon: "success",
                    dangerMode: false,
                })
                .then((value) => {
                    refreshPage()
                });
                // listCount = listCount + 1;
                // setListCount(listCount);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // alert(error.message);
                swal({
                    title: "Errors Found",
                    text: error.message,
                    icon: "warning",
                    dangerMode: false,
                })
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }
    
    let allClients = [];
    let clientList = [];
    // console.log(props)
    if(props.items){
        allClients = removeDuplicates(splitColumn(props.items, "client_name"));
        // console.log(allClients)
        allClients.forEach(function(allClient){
            clientList.push({clientName:allClient});
        })
        console.log({clientList})
    }

    const drpref = useRef();
    return (
        <div className="col-12 flex flex-column align-items-center text-left">
            <form id="addrecordForm" className='row form-theme' onSubmit={handleSubmit}>
                <div className="col-12">
                    <small className='d-block text-left full-width'>Add Record By Client</small>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <Calendar className="field-small p-inputtext-sm" placeholder="Add Date" name="date" value={newRecordData.date} onChange={handleInput} dateFormat="d MM yy" required />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row d-none">
                    <InputText className="p-inputtext-sm" placeholder="Freelancer Name" autoComplete="off" name="freelancer_name" value={newRecordData.freelancer_name} onChange={handleInput} disabled required />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    {/* <InputText className="p-inputtext-sm" placeholder="Client Name" autoComplete="off" name="client_name" value={newRecordData.client_name} onChange={handleInput} required/> */}
                    <Dropdown className="p-inputtext-sm" placeholder="Client Name" options={clientList} optionLabel="clientName" autoComplete="off" name="client_name" value={newRecordData.client_name} onChange={handleInput} ref={drpref} editable/>
                </div>
                {projectList.length > 0 && 
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <Dropdown className="p-inputtext-sm" placeholder="Select Project" options={projectList} optionLabel="name" autoComplete="off" name="project_id" value={newRecordData.project_id} onChange={handleInput} required/>
                </div>
                }
                <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
                    <Dropdown className="p-inputtext-sm" placeholder="Type Of Contract" options={typeOfContracts} optionLabel="name" autoComplete="off" name="type_of_contract" value={newRecordData.type_of_contract} onChange={handleInput} required/>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="HH:MM" autoComplete="off" name="worked_hours" value={newRecordData.worked_hours} onChange={handleInput} required/>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 field-row">
                    <InputTextarea className="p-inputtext-sm" placeholder="Task Memo" autoComplete="off" name="task_memo" value={newRecordData.task_memo} onChange={handleInput} required/>
                </div>
                <div className="col-12 col-md-12 col-lg-12 field-row">
                    <Button type='submit' value="Submit" label="Add Record" in-progress="Adding.." size="Small" className='small' />
                </div>
            </form>
        </div>
    )
}
export default Addrecord;