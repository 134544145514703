// import './leadsbysource.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect }  from 'react';
import Porjectlist from './Projectlist'
import Addporject from './Addproject'
import { TabView, TabPanel } from 'primereact/tabview';
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import {isLoggedIn, getPrivacyStandard} from '../helpers';
let userData = isLoggedIn(Cookies);
let privacyControl = getPrivacyStandard(Cookies);
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Projects = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [reportings, setReportings] = useState([]);
    let [listCount, setListCount] = useState(1);

    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/projects";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
                console.log({result})
            }

            let dailyReporting = domainOrigin+"/daily-reporting";
            setIsLoaded(false);
            const dailyReportings = await axios.get(dailyReporting);
            // After fetching data stored it in posts state.
            if(dailyReportings.status === 200){
                let result = dailyReportings.data;
                setIsLoaded(true);
                setReportings(result.reverse());
                console.log({result})
            }
        }
        getCount()
        // forceUpdate();
    }, [listCount])

    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
    return (
        <div className='leads-board full-width'>
            <TabView className="p-0">
                

                {privacyControl === false &&
                    <TabPanel header="All Records" className="p-0">  
                    <Porjectlist viewMode="all" dailyReportings={reportings} userDetails={userData} />
                    </TabPanel>
                }

                <TabPanel header="Add/Update Projects" className="p-0">    
                    <div className='container-fluid full-width'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-3 pt-2 bg-grey'>
                                <Addporject items={items} />
                            </div>
                            <div className='col-12 col-md-12 col-lg-9 px-0'>
                                <Porjectlist items={items} dailyReportings={reportings} viewMode="only-user" userDetails={userData} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                
                {/* <TabPanel header="Performance" className="p-0">  
                    <Reporting />
                </TabPanel>
                <TabPanel header="Analysis" className="p-0">  
                    <Recordsanalysis />
                </TabPanel> */}
            </TabView>
        </div>
    )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}
export default Projects;